
  .sq-dialog .inner-container {
    resize: both;
    max-width: 95vw;
    min-height: 20vh;
    &.size-small {
      min-width: 40%;
    }
    &.size-medium {
      min-width: 60%;
    }
    &.size-large {
      min-width: 80%;
    }
  }

  .sq-dialog-full-height {
    min-height: 90vh;
  }

  .draggable-dialog {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 300px;
    .is--draggable {
      cursor: move;
    }
  }

  .pdf-vue3-main.pdf {
    cursor: initial;
    .pdf-vue3-backToTopBtn {
      cursor: pointer;
    }
  }
